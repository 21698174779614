<template>
  <div class="container-fluid">
    <div id="main" class="container-md">
      <i-navbar ref="nav"></i-navbar>
      <div id="person_list" :class="{ 'd-none': pageType == 'info' }">
        <div id="tag_section" class="row mt-5">
          <div class="col text-left">
            <li>身份標籤：</li>
            <div class="row">
              <div class="col ml-5">
                <span
                  class="badge badge-light ml-1 mt-1"
                  v-for="item in careerList"
                  :key="item"
                  >{{ item }}
                  <button
                    type="button"
                    class="btn btn-sm pt-0"
                    @click="removeCareerItem(item)"
                  >
                    X
                  </button></span
                >
              </div>
            </div>
          </div>
          <div class="col text-left">
            <li>社會關係標籤：</li>
            <div class="row">
              <div class="col ml-5">
                <span
                  class="badge badge-light ml-1 mt-1"
                  v-for="item in relationList"
                  :key="item"
                  >{{ item }}
                  <button
                    type="button"
                    class="btn btn-sm pt-0"
                    @click="removeRelationItem(item)"
                  >
                    X
                  </button></span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- table -->
        <div id="person_table" class="row mt-3">
          <div class="col-2"><span>編 號</span></div>
          <div class="col-2"><span>姓 名</span></div>
          <div class="col-2"><span>年 份</span></div>
          <div class="col-3">
            <span>身 份</span>
            <div id="careerMenu" class="dropdown float-right">
              <a
                class="dropdown-toggle"
                id="dropdownMenuButton"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                選單
              </a>
              <div
                class="dropdown-menu dropdown-menu-right dropdown-multicol"
                aria-labelledby="dropdownMenuButton"
              >
                <div class="dropdown-row">
                  <label class="dropdown-item"
                    ><input type="checkbox" value="士人" v-model="careerList" />
                    士人</label
                  >
                  <label class="dropdown-item"
                    ><input type="checkbox" value="文人" v-model="careerList" />
                    文人</label
                  >
                  <label class="dropdown-item"
                    ><input type="checkbox" value="伶人" v-model="careerList" />
                    伶人</label
                  >
                  <label class="dropdown-item"
                    ><input type="checkbox" value="刻工" v-model="careerList" />
                    刻工</label
                  >
                </div>

                <div class="dropdown-row">
                  <label class="dropdown-item"
                    ><input type="checkbox" value="書商" v-model="careerList" />
                    書商</label
                  >
                  <label class="dropdown-item"
                    ><input type="checkbox" value="學者" v-model="careerList" />
                    學者</label
                  >
                  <label class="dropdown-item"
                    ><input type="checkbox" value="出版" v-model="careerList" />
                    出版</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="收藏家"
                      v-model="careerList"
                    />
                    收藏家</label
                  >
                </div>

                <div class="dropdown-row">
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="藏書家"
                      v-model="careerList"
                    />
                    藏書家</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="實業家"
                      v-model="careerList"
                    />
                    實業家</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="漢學家"
                      v-model="careerList"
                    />
                    漢學家</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="政治人物"
                      v-model="careerList"
                    />
                    政治人物</label
                  >
                </div>

                <div class="dropdown-row">
                  <label class="dropdown-item"
                    ><input type="checkbox" value="其他" v-model="careerList" />
                    其他</label
                  >
                </div>

                <div class="dropdown-row text-right">
                  <a
                    class="dropdown-item text-center"
                    @click.prevent="selectAllCareer"
                    >全選</a
                  >
                  <a
                    class="dropdown-item text-center"
                    @click.prevent="clearSelectedCareer"
                    >清除</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <span>社會關係 </span>
            <div id="relationMenu" class="dropdown float-right">
              <a
                class="dropdown-toggle"
                id="dropdownMenuButton"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                選單
              </a>
              <div
                class="dropdown-menu dropdown-menu-right dropdown-multicol"
                aria-labelledby="dropdownMenuButton"
              >
                <div class="dropdown-row">
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="葉德輝友人"
                      v-model="relationList"
                    />
                    葉德輝友人</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="葉德輝同年"
                      v-model="relationList"
                    />
                    葉德輝同年</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="葉德輝同學"
                      v-model="relationList"
                    />
                    葉德輝同學</label
                  >
                </div>

                <div class="dropdown-row">
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="葉德輝政敵"
                      v-model="relationList"
                    />
                    葉德輝政敵</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="葉德輝師長"
                      v-model="relationList"
                    />
                    葉德輝師長</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="葉德輝學生"
                      v-model="relationList"
                    />
                    葉德輝學生
                  </label>
                </div>

                <div class="dropdown-row">
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="葉德輝親族"
                      v-model="relationList"
                    />
                    葉德輝親族</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="其他"
                      v-model="relationList"
                    />
                    其他</label
                  >
                </div>

                <div class="dropdown-row text-right">
                  <a
                    class="dropdown-item text-center"
                    @click.prevent="selectAllRelation"
                    >全選</a
                  >
                  <a
                    class="dropdown-item text-center"
                    @click.prevent="clearSelectedRelation"
                    >清除</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- person list -->
        <table id="personList" class="table table-striped mt-1">
          <tbody class="row">
            <tr
              class="row"
              v-for="(item, index) in filterPersonList"
              :key="item.id"
            >
              <th scope="row" class="col-2">{{ parseInt(index) + 1 }}</th>
              <td class="col-2">
                <a href="#" @click.prevent="openPersonInfo(item.name)">{{
                  item.name
                }}</a>
              </td>
              <td class="col-2">{{ item.year }}</td>
              <td class="col-3">{{ item.career }}</td>
              <td class="col-3">{{ item.relation }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- person info -->
      <div
        id="person_info"
        :class="{ 'd-none': pageType == 'list' }"
        class="row mt-5"
        v-if="personInfo.name"
      >
        <div class="col-4">
          <div class="row">
            <div class="col">
              <div class="card_title">
                <span class="">人物肖像</span>
              </div>
              <div class="card_content_image mt-1">
                <img :src="personInfo.imageUrls" alt="..." />
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col">
              <div class="card_title">
                <span class="">人物資料表</span>
              </div>
              <div class="card_content mt-1">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td class="first_td"><span>姓 名</span></td>
                      <td>
                        <span>{{ personInfo.name }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="first_td"><span>別 名</span></td>
                      <td>
                        <span>{{ personInfo.nickname }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="first_td"><span>性 別</span></td>
                      <td>
                        <span>{{ personInfo.gender }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="first_td"><span>國 籍</span></td>
                      <td>
                        <span>{{ personInfo.country }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="first_td"><span>籍 貫</span></td>
                      <td>
                        <span>{{ personInfo.address }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="first_td"><span>生 年</span></td>
                      <td>
                        <span>{{ personInfo.birth }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="first_td"><span>卒 年</span></td>
                      <td>
                        <span>{{ personInfo.death }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="first_td"><span>年 齡</span></td>
                      <td>
                        <span>{{ personInfo.age }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="first_td"><span>官 名 </span></td>
                      <td>
                        <span>{{ personInfo.position }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-8">
          <!-- label -->
          <div class="col">
            <div class="card_title">
              <span class="">標 籤</span>
            </div>
            <div class="card_label mt-1 text-left">
              <div
                class="labelMenu dropdown d-inline-block m-1"
                v-for="item in labelObjList"
                :key="item.label"
              >
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ item.label }}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item"
                    href="#"
                    v-for="name in item.list"
                    :key="name"
                    @click.prevent="openPersonInfo(name)"
                  >
                    {{ name }}</a
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- content -->
          <div class="col mt-2">
            <div class="card_title">
              <span>小傳正文</span>
            </div>
            <div class="card_content_text mt-1 text-left p-4">
              <div class="row">
                <div class="col-9 text-left">
                  <span class="title"> {{ personInfo.name }}</span>
                </div>
                <div class="col-3 text-right">
                  <a
                    href="#"
                    class="m-1"
                    type="button"
                    style="font-size: 20px"
                    :class="{ active: curr_content_font_size_active[0] }"
                    @click.prevent="setContentSize(0, '20px')"
                    >A</a
                  >
                  <a
                    href="#"
                    class="m-1"
                    style="font-size: 18px"
                    :class="{ active: curr_content_font_size_active[1] }"
                    @click.prevent="setContentSize(1, '18px')"
                    >A</a
                  >
                  <a
                    href="#"
                    class="m-1"
                    style="font-size: 16px"
                    :class="{ active: curr_content_font_size_active[2] }"
                    @click.prevent="setContentSize(2, '16px')"
                    >A</a
                  >
                </div>
              </div>

              <div class="row">
                <div class="col" :style="{ 'font-size': contentFontSize }">
                  <p v-html="personInfo.content"></p>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-9 text-left">
                  <span class="title"> 參考書目 </span>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col">
                  <p
                    v-html="personInfo.ref"
                    :style="{ 'font-size': contentFontSize }"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      id="go_back_btn"
      type="button"
      class="btn btn-info"
      v-if="pageType == 'info'"
      @click="pageType = 'list'"
    >
      回到人物列表
    </button>
    <i-footer></i-footer>
  </div>
</template>

<script>
import $ from 'jquery';
import navbar from '../components/Navbar.vue';
import footer from '../components/Footer.vue';

export default {
  mounted() {
    this.$refs.nav.isActiveList = [0, 0, 0, 0, 0, 0, 0, 0];
    this.$refs.nav.isActiveList[3] = 1;

    if (this.$route.query.person) {
      const person = `${this.$route.query.person}`;
      this.openPersonInfo(person);
    }

    $(document).ready(() => {
      $('.dropdown-menu').on('click', (e) => {
        // avoid dropdown menu close when click item
        e.stopPropagation();
      });
    });

    const biographyListAPI = `${process.env.VUE_APP_APIPATH}api/biography-list/`;
    this.$http.get(biographyListAPI).then((response) => {
      // console.log(response.data);
      this.personList = response.data;
      this.updateFilterPersonList();
    });
  },
  components: {
    'i-navbar': navbar,
    'i-footer': footer,
  },
  data() {
    return {
      contentFontSize: '20px',
      pageType: 'list',
      careerList: [],
      relationList: [],
      curr_content_font_size_active: [1, 0, 0],
      personList: [],
      filterPersonList: [],
      personInfo: {},
      labelObjList: [],
    };
  },
  watch: {
    careerList: {
      handler() {
        this.updateFilterPersonList();
      },
      deep: true,
    },
    relationList: {
      handler() {
        this.updateFilterPersonList();
      },
      deep: true,
    },
  },
  methods: {
    removeCareerItem(item) {
      const itemIndex = this.careerList.indexOf(item);
      this.careerList.splice(itemIndex, 1);
    },
    removeRelationItem(item) {
      const itemIndex = this.relationList.indexOf(item);
      this.relationList.splice(itemIndex, 1);
    },
    clearSelectedCareer() {
      this.careerList = [];
    },
    selectAllCareer() {
      this.careerList = [
        '士人',
        '文人',
        '伶人',
        '刻工',
        '書商',
        '學者',
        '出版',
        '收藏家',
        '藏書家',
        '實業家',
        '漢學家',
        '政治人物',
        '其他',
      ];
    },
    clearSelectedRelation() {
      this.relationList = [];
    },
    selectAllRelation() {
      this.relationList = [
        '葉德輝友人',
        '葉德輝同年',
        '葉德輝同學',
        '葉德輝政敵',
        '葉德輝師長',
        '葉德輝學生',
        '葉德輝親族',
        '其他',
      ];
    },
    updateFilterPersonList() {
      if (this.careerList.length === 0 && this.relationList.length === 0) {
        this.filterPersonList = { ...this.personList };
      } else {
        this.filterPersonList = [];
        this.personList.forEach((person) => {
          let isInCareer = 0;
          this.careerList.forEach((carreer) => {
            if (person.career.includes(carreer)) {
              isInCareer = 1;
            }
          });

          let isInRelation = 0;
          this.relationList.forEach((relation) => {
            if (person.relation.includes(relation)) {
              isInRelation = 1;
            }
          });

          if (isInCareer || isInRelation) {
            this.filterPersonList.push(person);
          }
        });
      }
    },
    openPersonInfo(personName) {
      const personInfoAPI = `${process.env.VUE_APP_APIPATH}api/biography-info/?person_name=${personName}&format=json`;
      // console.log(personInfoAPI);
      this.$http.get(personInfoAPI).then((response) => {
        // console.log(response.data);
        if (response.data[0]) {
          // console.log(response.data[0]);
          // update person info
          this.personInfo = { ...response.data[0] };
          this.personInfo.imageUrls = `${process.env.VUE_APP_IMAGE_APIPATH}biography/${this.personInfo.imageUrls}`;

          this.labelObjList = [];
          this.personInfo.labels = this.personInfo.labels.split('、');

          // update person labels
          const newLabelObjList = [];
          this.personInfo.labels.forEach((item) => {
            const personLabelAPI = `${process.env.VUE_APP_APIPATH}api/biography-label/?label=${item}`;
            console.log(personLabelAPI);
            this.$http.get(personLabelAPI).then((responseX) => {
              console.log(responseX.data);
              if (responseX.data.length > 0) {
                responseX.data.forEach((itemX) => {
                  const newItem = {};
                  newItem.label = itemX.label;
                  newItem.list = itemX.people.split('、');
                  newLabelObjList.push(newItem);
                });
              }
            });
          });
          this.pageType = 'info';
          this.labelObjList = newLabelObjList;
        }
      });
    },
    setContentSize(activeIndex, fontSize) {
      this.contentFontSize = fontSize;
      this.curr_content_font_size_active = [0, 0, 0];
      this.curr_content_font_size_active[activeIndex] = 1;
    },
  },
};
</script>

<style lang="sass" scoped>
#go_back_btn
  position: fixed
  right: 0
  bottom: 0
.card_title
  border-top-left-radius: 8px
  border-top-right-radius: 8px
  background-color: #677b7b
  font-size: 16px
  letter-spacing: 2px
  line-height: 24px
  color: #ffffff
  font-family: "Microsoft Jheng Hei Regular"
  text-align: center

.card_content_image
  border-bottom-left-radius: 8px
  border-bottom-right-radius: 8px
  background-color: #dbe4e4
  img
    height: 30vh

.card_content
  background-color: #dbe3e4
  height: 40vh
  overflow-x: hidden
  overflow-y: auto
  padding: 15px 10px

.card_label
  background-color: #efefef
  height: 10vh
  padding: 1rem

.card_content_text
  background-color: #efefef
  height: 60vh
  overflow-x: hidden
  overflow-y: auto
  .title
    font-size: 20px
    letter-spacing: 2px
    line-height: 30px
    color: #231815
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"
  a
    display: inline-block
    font-size: 22px
    letter-spacing: 2px
    line-height: 30px
    color: #6b6a6a
    font-family: "Microsoft Jheng Hei Regular"
  a.active
    background-color: #c2dada
    border-radius: 50%
    width: 30px
    height: 30px
    text-align: center

.labelMenu
  button
    border-radius: 3px
    filter: drop-shadow(2.121px 2.121px 1px rgba(0,0,0,0.18))
    background-color: #c2dada
    font-size: 14px
    letter-spacing: 1px
    line-height: 24px
    color: #231815
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"
  .dropdown-menu
    filter: drop-shadow(4.243px 4.243px 3px rgba(0,0,0,0.25))
    background-color: #c2dada
    height: 20vh
    overflow-x: hidden
    overflow-y: auto
  .dropdown-item
    font-size: 14px
    letter-spacing: 1px
    line-height: 20px
    color: #1291a4
    font-weight: 300
    font-family: "Microsoft Jheng Hei Light"

.table-bordered
  td
    background-color: #aad0d0
    border: 2px solid white
    padding: 2px

.first_td
  width: 30%
  font-size: 14px
  letter-spacing: 1px
  line-height: 24px
  color: #3e3a39
  font-weight: bold
  font-family: "Microsoft Jheng Hei Bold"

#main
  height: 100vh
  min-width: 1200px

#tag_section
  li
    font-size: 14px
    letter-spacing: 1px
    line-height: 24px
    color: #1b566e
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"
    list-style-type: none
    &:before
      content: '\00b7'
      font-size: 100px
      line-height: 24px
      vertical-align: middle
  span
    font-size: 14px
    letter-spacing: 1px
    line-height: 24px
    color: #1b566e
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"

#person_table
  div
    border: 1px solid white
    background-color: #c3d3da
    span
      font-size: 16px
      letter-spacing: 2px
      line-height: 24px
      color: #1b566e
      font-weight: bold
      font-family: "Microsoft Jheng Hei Bold"
    ul
      display: inline
  a
    font-size: 14px
    letter-spacing: 1px
    line-height: 24px
    color: #1b566e
    font-weight: 300
    font-family: "Microsoft Jheng Hei Light"

#careerMenu
  display: inline
  border: none !important

  .dropdown-row
    border: none !important

  .dropdown-multicol
    width: 30em

  .dropdown-item
    display: inline-block
    width: 25%
    font-size: 14px
    letter-spacing: 1px
    line-height: 24px
    color: #1b566e
    font-family: "Microsoft Jheng Hei Regular"

#relationMenu
  display: inline
  border: none !important

  .dropdown-row
    border: none !important

  .dropdown-multicol
    width: 25em

  .dropdown-item
    display: inline-block
    width: 33%
    font-size: 14px
    letter-spacing: 1px
    line-height: 24px
    color: #1b566e
    font-family: "Microsoft Jheng Hei Regular"

#personList
  tbody
    display: block
    height: 500px
    overflow: auto
</style>
